// customAlert.js
// this component renders message on the user Screen
import { styled } from "@mui/material/styles";
import { Alert } from "@mui/material";

const CustomAlert = styled(Alert)(({ theme }) => ({
  position:"absolute",
  bottom:10,
  zIndex:2,
//   background:"#343434",
  boxShadow:"0px 0px 2px #000",
  [theme.breakpoints.down("sm")]:{
    left:"50%",
    transform:"translateX(-50%)"
  },
  [theme.breakpoints.up("sm")]:{
    left:10
  }
  // mobile devies
}));

export default CustomAlert;
