// auth.js
// this function renders the authentication form
import { useState } from "react";
import { Paper } from "@mui/material";
import { ReactComponent as Logo } from "../../comp/auth_logo.svg";
import { LinearProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Alert } from "@mui/material";
import CustomAlert from "./customAlert";
export default function Auth(props) {
  const [loader, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    severity: "success",
  });
  // message login functions
  function hideMessageAfterTime(time = 3000) {
    // this function hides the message after them time
    setTimeout(() => {
      setMessage({ message: "", severity: "success" });
    }, time);
  }
  function setAlertMessage(message = "", time = false) {
    setMessage({ message: message, severity: "warning" });
    if (time === true) {
      hideMessageAfterTime();
    }
  }
  function setErrorMessage(message = "", time = false) {
    setMessage({ message: message, severity: "error" });
    if (time === true) {
      hideMessageAfterTime();
    }
  }
  return (
    <>
      {message.message.length > 0 ? <CustomAlert severity={message.severity} >{message.message}</CustomAlert> : <></>}
      {/* linear loader */}
      {loader === true ? (
        <LinearProgress></LinearProgress>
      ) : (
        <div style={{ height: "4px" }}></div>
      )}
      {/* main component */}
      <Paper
        sx={{
          background: "transparent",
          boxShadow: "none",
          borderRadius: "0",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AuthCard
          loader={loader}
          setLoading={setLoading}
          setAlertMessage={setAlertMessage}
          setErrorMessage={setErrorMessage}
        ></AuthCard>
      </Paper>
    </>
  );
}

function AuthCard(props) {
  return (
    <Paper
      sx={{
        height: "100%",
        background: "transparent",
        boxShadow: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Logo />
      {/* authentication form */}
      <Outlet
        context={{
          loading: [props.loader, props.setLoading],
          message: {
            setAlertMessage: props.setAlertMessage,
            setErrorMessage: props.setErrorMessage,
            
          },
        }}
      />
    </Paper>
  );
}
