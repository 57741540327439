// Preloader.js
// is first component to load, displays a loader while the application is being fetched in the background
import { Suspense } from "react";
import Loader from "./loader/loader";
import MainRouter from "../router/mainRouter";
// import firebaseAuthHook 
import useFirebaseAuth from "../utils/firebase/firebase_auth_hook";

export default function PreLoader(props) {
  // getting user form the firebaseAuthHook
  const user= useFirebaseAuth();
  return (
    <Suspense fallback={<Loader></Loader>}>
      {
        // show preloader is the user is not defined
        user===undefined?<Loader></Loader>:<MainRouter/>
      }
    </Suspense>
  );
}
