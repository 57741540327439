// header.js
// renders the header for the web application
import { AppBar } from "@mui/material";
import HeaderLogo from "./header_logo";
import { styled } from "@mui/material/styles";
import useFirebaseAuth from "../../utils/firebase/firebase_auth_hook";
import UnauthorizedRightHeader from "./unauthorizedRightHeader";
import AuthorizedRightHeader from "./authorizedRightHeader";
const CustomAppBar = styled(AppBar)(({ theme }) => ({
    minWidth:"320px",
    overflow:"auto",
    position:"relative",
  boxShadow: "none",
  background: "transparent",
//   opacity: 0.2,
  display:"flex",
  flexDirection:"row",
  justifyContent:"space-between",
  alignItems:"center",
  [theme.breakpoints.down("sm")]:{
    // small screen
    padding:"15px 5px"
  },
  [theme.breakpoints.up("sm")]:{
    // large screen
    padding: "25px 30px",
  }
}));
export default function Header(props) {
  const user = useFirebaseAuth();
  return (
    <CustomAppBar>
      <HeaderLogo />
      {/* header right part */}
      {user === false ? <UnauthorizedRightHeader /> : <AuthorizedRightHeader />}
    </CustomAppBar>
  );
}
