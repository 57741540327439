// authRouting.js
// this component manages the routing of the pages which are NOT behind authentication
import { Routes, Route } from "react-router-dom";
// =========== auth pages import
import Auth from "../pages/auth/auth";
import AuthLoginForm from "../pages/auth/authLoginForm";
import AuthSignUpForm from "../pages/auth/authSignUpForm";
import NotFound from "../pages/not_found/not_found";
// =========== logic
export default function AuthRouting(props) {
  return (
    <Routes>
      <Route path="/" element={<p>Landing Page</p>} />
      <Route path="/auth" element={<Auth />}>
        <Route index element={<AuthLoginForm />} />
        <Route path="signIn" element={<AuthLoginForm />} />
        <Route path="logIn" element={<AuthLoginForm />} />
        <Route path="signUp" element={<AuthSignUpForm />} />
      </Route>
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
