// useSystemTheme.js
// this hook get the theme of the user system and return string "light" / "dark"
import { useState, useEffect } from "react";
export default function useSystemTheme() {
  const [theme, setTheme] = useState("dark"); //default to dark theme

  useEffect(() => {
    const darkModeQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // Initial theme detection
    setTheme(darkModeQuery.matches ? "dark" : "light");

    // Update theme when the system preference changes
    const listener = (event) => {
      setTheme(event.matches ? "dark" : "light");
    };

    darkModeQuery.addEventListener("change", listener);

    // Cleanup function to remove the listener when the component unmounts
    return () => darkModeQuery.removeEventListener("change", listener);
  }, []);

  return theme;
}
