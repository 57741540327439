// alternativeAuth.js
// this component renders additional auth system for the application
import { styled } from "@mui/material/styles";
import {motion} from "framer-motion"
import SignInWithGoogle from "./signInWithGoogle";
const Line = styled(motion.span)(({ theme }) => ({
    display:"inline-block",
    width:"130px",
    height:"1px",
    background:"#AEAEAE",
    margin:"0px 10px",
    
    // mobile devies
  }));
export default function AlternativeAuth(props){
    return(
        <>
        <div style={{
            position:'relative',
            marginTop:"50px",
            display:"flex",
            alignItems:"center"
        }}>
            
            <Line/> <span style={{fontWeight:"bolder"}}>OR </span><Line/>
            
        </div>
        <SignInWithGoogle loading={props.loading} onClick={props.onClick} title={props.title}></SignInWithGoogle>
        </>
    )
}