// routing.js
// this component actual routing of the application after login
import { Route, Routes } from "react-router-dom";
//========================================
// application pages behind authentication
//========================================
import NotFound from "../pages/not_found/not_found";
export default function AppRouting(props) {
  return (
    // Application Routing
    <Routes>
      <Route path="/" element={<p>this is home page under auth</p>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
