// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBYtAjl3hDVDfbz0molRwc-HAxzT1uceKg",
  authDomain: "forsplitup.firebaseapp.com",
  projectId: "forsplitup",
  storageBucket: "forsplitup.appspot.com",
  messagingSenderId: "55294586086",
  appId: "1:55294586086:web:3d0d9be44b20ac2ad0df27",
  measurementId: "G-M1BVH9XBWY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// initialized firebase auth
const auth = getAuth(app);

export { app as firebase_app, analytics, auth as firebase_auth }