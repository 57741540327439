// createNewAccount.js
// this component renders a button to redirect use to signup Form
import { useNavigate } from "react-router-dom";
import Style from "./forgotPassword.module.css";
export default function CreateNewAccout(props) {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => {
        navigate("/auth/signup");
      }}
      className={Style.button}
      style={{
        marginRight: "initial",
        color: "#ccc",
      }}
    >
      <span style={{}}>Sign Up ?</span>
    </button>
  );
}
