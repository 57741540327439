import { styled } from "@mui/material/styles";
import { TextField, Button } from "@mui/material";

const CustomInput = styled(TextField)(({ theme }) => ({
  width: "325px",
  marginTop: "30px",
  borderRadius: 100,
  height: "60px",
  // mobile devies
}));

const CustomButton = styled(Button)(({ theme }) => ({
  marginTop: "30px",
  fontSize: "16px",
  padding: "7px 100px",
  fontWeight: "bolder",
  borderColor: "#686868",
  borderWidth: "2 !Important",
  color: "#BFBFBF",
}));

export { CustomButton, CustomInput };
