// signInWithGoogle.js
// this component renders the sign in with google option
import {ReactComponent as GoogleIcon} from "../../comp/googleIcon.svg"
import Style from "./signInWithGoogle.module.css"
export default function SignInWithGoogle(props){
    return (
        <>
        <button disabled={props.loading} onClick={props.onClick} className={Style.button} >
            <GoogleIcon style={{transform:"scale(.7)"}}/>
            <p style={{marginLeft:"10px",fontWeight:"550",fontSize:16}}>{props.title}</p>
        </button>
        </>

    )
}