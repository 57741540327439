import { BrowserRouter as Router } from "react-router-dom";
import AppRouting from "./appRouting";
import AuthRouting from "./authRouting";
// setting the theme of the webapplicat
import useSystemTheme from "../utils/useSystemTheme";
import { ThemeProvider } from "@mui/material";
import { lightTheme, darkTheme } from "../utils/appTheme";
import useFirebaseAuth from "../utils/firebase/firebase_auth_hook";
export default function MainRouter(props) {
  const theme = useSystemTheme();
  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <Router>
        {/* auth Routing */}
        <RoutingSwitch />
      </Router>
    </ThemeProvider>
  );
}

// this function switches the routing between AppRouting and AuthRouting
function RoutingSwitch(props) {
  // importing user
  const user=useFirebaseAuth();
  if(user===false){
    return <AuthRouting></AuthRouting>
  }else{
    return <AppRouting></AppRouting>
  }
}
