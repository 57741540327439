// UnauthorizedRightHeader.js
// renders the right part of header when user is unauthorized
import { Button } from "@mui/material"
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
const CustomButton = styled(Button)(({ theme }) => ({  
  [theme.breakpoints.down("sm")]: {
    // for mobile screen
    fontSize:"12px",
    marginLeft:"12px"
  },
  [theme.breakpoints.up("sm")]:{
    // for large screen
    marginLeft:"20px"
  }
}));
export default function UnauthorizedRightHeader(props){
    const navigate=useNavigate();
    return (
        <div styled={{display:"flex"}}>
            <CustomButton onClick={()=>{navigate("/auth/login")}} sx={{fontWeight:"bolder"}} variant="contained">LogIn</CustomButton>
            <CustomButton onClick={()=>{navigate("/auth/signup")}} sx={{fontWeight:"bolder"}} variant="outlined">Sign Up</CustomButton>
        </div>
    )
}