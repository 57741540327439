// appTheme.js
// this file return two theme lightTheme and darkTheme

import { createTheme } from "@mui/material";


// DARK THEME

// pallet for dark theme
const darkTheme=createTheme({
    palette: {
        primary: {
            main: "#32CD32"
        },
        mode: 'dark',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1200,
            xl: 1536,
        },
    },
});


// LIGHT THME

// pallet for light theme
// pallet for light theme
const lightTheme=createTheme({
    palette: {
        primary: {
            main: "#32CD32"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 800,
            lg: 1200,
            xl: 1536,
        },
    },
});




export {darkTheme,lightTheme}