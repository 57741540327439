// loader.js
// is rendered by the preloader.js on the client screen while the application is being loaded
import { FlatTree, motion } from "framer-motion";
import { CircularProgress } from "@mui/material";
import Style from "./loader.module.css";

const variants={
  initial:{

  },
  animate:{

  },
  exit:{

  }
}

export default function Loader(props) {
  return (
    <motion.div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        flexDirection: "column",
        // background: "linear-gradient(90deg, rgba(146,223,146,.2) 0%, rgba(254,254,254,0.11390493697478987) 100%)"
      }}
    >
      <Spinner></Spinner>
      <motion.div
        style={{
          position: "absolute",
          bottom: "0",
          marginBottom: "10px",
        }}
      >
        <h3>
          <span
            style={{
              color: "#32CD32",
              fontWeight: "700",
            }}
          >
            Split
          </span>
          <span style={{
            fontWeight:"900",
            
          }}>Up</span>
        </h3>
      </motion.div>
    </motion.div>
  );
}

function CircularLoader(props) {}

function Spinner(props) {
  return <motion.span className={Style.spinner}></motion.span>;
}
