// not_found.js
// this page renders a 404 not found page
import Header from "../../comp/header/header"
import { Paper } from "@mui/material"
import {ReactComponent as FourZeroFour} from "./404.svg"
import { styled } from "@mui/material/styles";
import backgroundSVG from "../../comp/Vector.svg"
const CustomBody = styled(Paper)(({ theme }) => ({  
    background:"none",
    boxShadow:"none",
    position:"realtive",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
  [theme.breakpoints.down("sm")]: {
    // for mobile screen
    fontSize:"12px",
    marginLeft:"12px",
  },
  [theme.breakpoints.up("sm")]:{
    // for large screen
    marginLeft:"20px"
  }
}));
export default function NotFound(props){
    return (
        <>
        <Paper sx={{
            background:"none",
            boxShadow:"none",
            backgroundImage: `url(${backgroundSVG})`,
            backgroundRepeat:"repeat",
            height:"100%",
            position:"relative"
            
        }}>
        <Header></Header> 
        {/* body */}
        <CustomBody>
            <FourZeroFour style={{
                marginTop:"100px"
            }}/>
            <h2 style={{
                marginTop:"50px",
                wordSpacing:"10px",
                color:"#D9D9D9"
            }}>Page Not Found</h2>
            <p style={{marginTop:"50px",color:"#767676",textAlign:"center"}}>The page you requested could not be found. It may have been moved, deleted, or never existed.</p>
        </CustomBody>
        {/* footer */}
        </Paper>
        </>
    )
}