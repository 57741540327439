// auth_header_right.js
// renders right part of header when not autherised
import useFirebaseAuth from "../../utils/firebase/firebase_auth_hook";
import { Avatar } from "@mui/material";

export default function AuthorizedRightHeader(props){
    const user=useFirebaseAuth();
    return (
        <div>
            <Avatar/>
        </div>
    )
}