//header_logo.js
// this renders a componet which renders header logo in header
import { ReactComponent as Logo } from "./header_logo.svg";
import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  background:'transparent',
  border:"0px",
  boxShadow:"none",
  "& svg":{
    // background:"red"
  },
  [theme.breakpoints.down("sm")]: {
    // for mobile screen
    fontSize:"16px",
    "& svg": {
      width: 50,
      height:50
    }
  },
  [theme.breakpoints.up("sm")]:{
    // for large screen
    fontSize:20,
    "& svg":{
        width:80,
        height:80
    }
  }
}));
export default function HeaderLogo(props) {
  return (
    <CustomPaper>
      <Logo />
      <p style={{ fontWeight: "bolder", color: "#fff" }}>
        <span style={{ color: "#32CD32" }}>Split</span> Up
      </p>
    </CustomPaper>
  );
}
