import { createUserWithEmailAndPassword } from "firebase/auth";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { firebase_app, firebase_auth } from "../../utils/firebase/init.js";
// ===============
import {
  CustomButton,
  CustomInput,
} from "../../comp/auth_page_form_components/auth_form_components.js";
import AlternativeAuth from "./alternativeAuth.js";
// ===============
import { useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import SignInHyperlink from "./signInHyperlink.js";
export default function AuthSignUpForm(props) {
  // ==============
  const navigate = useNavigate();
  const [loading, setLoading] = useOutletContext().loading;
  const message = useOutletContext().message;
  // ==============
  const emailRef = useRef();
  const passwordRef = useRef();
  const buttonRef = useRef();
  // ==============
  // functional logic
  function extractErroMessage(errCode) {
    errCode = errCode.split("/");
    errCode = errCode[1];
    errCode = errCode.split("-");
    errCode = errCode.join(" ");
    return errCode;
  }
  function keyDownHandle(event, currentRef, nextRef) {
    // function to handle keyDown on Text Field
    if (event.keyCode !== 13) {
      return;
    }
    if (currentRef.current.value.length <= 0) {
      message.setErrorMessage("please fill the required input fields");
      return;
    }
    if (!nextRef) {
      // if there is no next input field
      setLoading(true);
      handleSubmit();
    } else {
      // focus on next element
      nextRef.current.focus();
    }
  }
  function handleSubmit(event) {
    // handle form submission
    // form validation
    let email = emailRef.current.value;
    email = email.trim();
    let password = passwordRef.current.value;
    password = password.trim();
    // ================
    // if email is not provied
    if (email.length <= 0) {
      // show error message
      message.setErrorMessage("please provide email");
      setLoading(false);
      emailRef.current.focus();
      return;
    }
    // if password is not provided
    if (password.length <= 0) {
      // show error message
      message.setErrorMessage("please provide password");
      setLoading(false);
      passwordRef.current.focus();
      return;
    }
    // ================
    // signup
    setLoading(true);
    createUserWithEmailAndPassword(firebase_auth, email, password)
      .then(() => {
        // signup successful
        navigate("/");
      })
      .catch((err) => {
        // sign up error
        message.setErrorMessage(extractErroMessage(err.code));
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleSignUpWithGoogle() {
    // function to handle sign in with google
    // alert("handle signin with google")
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      login_hint: "user@example.com",
    });
    setLoading(true);
    signInWithPopup(getAuth(firebase_app), provider)
      .then((result) => {
        //
        navigate("/");
      })
      .catch((err) => {
        // handle error
        message.setErrorMessage(extractErroMessage(err.code));
      })
      .finally(() => {
        setLoading(false);
      });
  }
  // ==============
  return (
    <>
      {/* email input field */}
      <CustomInput
        autoFocus
        inputRef={emailRef}
        label="email"
        required
        disabled={loading}
        onKeyDown={(event) => {
          keyDownHandle(event, emailRef, passwordRef);
        }}
      />
      {/* passowrd input field */}
      <CustomInput
        inputRef={passwordRef}
        label="password"
        required
        type="password"
        disabled={loading}
        onKeyDown={(event) => {
          keyDownHandle(event, passwordRef, buttonRef);
        }}
      />
      {/* sign in hyperlink */}
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          width: "100%",
          padding: 5,
        }}
      >
        <SignInHyperlink />
      </div>
      {/* sign up button */}
      <CustomButton
        onClick={handleSubmit}
        ref={buttonRef}
        variant="outlined"
        disabled={loading}
      >
        Sign Up
      </CustomButton>
      <AlternativeAuth
        onClick={handleSignUpWithGoogle}
        loading={loading}
        title="Sign up with Google"
      />
    </>
  );
}
