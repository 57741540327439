import { useRef } from "react";
import CreateNewAccout from "./createNewAccount.js";
import ForgotPassword from "./forgotPassword.js";
import AlternativeAuth from "./alternativeAuth.js";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// ==================
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { firebase_app, firebase_auth } from "../../utils/firebase/init.js";
// ==================
import {
  CustomButton,
  CustomInput,
} from "../../comp/auth_page_form_components/auth_form_components.js";
// ================== login form logic
export default function AuthLoginForm(props) {
  const navigate=useNavigate();
  const [loading, setLoading] = useOutletContext().loading;
  const message = useOutletContext().message;
  const emailRef = useRef();
  const passwordRef = useRef();
  const buttonRef = useRef();

  // functional logic
  function extractErroMessage(errCode){
    errCode=errCode.split("/");
    errCode=errCode[1];
    errCode=errCode.split("-");
    errCode=errCode.join(" ");
    return errCode 
  }
  function keyDownHandle(event, currentRef, nextRef) {
    // function to handle keyDown on Text Field
    if (event.keyCode !== 13) {
      return;
    }
    if (currentRef.current.value.length <= 0) {
      message.setErrorMessage("please fill the required input fields");
      return;
    }
    if (!nextRef) {
      // if there is no next input field
      setLoading(true);
      submitHandle();
    } else {
      // focus on next element
      nextRef.current.focus();
    }
  }
  function submitHandle(event) {
    // function to handle form submission
    // alert("handle submit")
    setLoading(true);
    // form validation
    let email=emailRef.current.value;
    email=email.trim();
    let password=passwordRef.current.value;
    password=password.trim();
    // if email is not provied
    if(email.length<=0){
      // show error message
      message.setErrorMessage("please provide email")
      setLoading(false);
      emailRef.current.focus();
      return
    }
    // if password is not provided
    if(password.length<=0){
      // show error message
      message.setErrorMessage("please provide password")
      setLoading(false);
      passwordRef.current.focus();
      return
    }
    // login
    signInWithEmailAndPassword(firebase_auth,email,password).then(()=>{
      navigate("/")
    }).catch((err)=>{
      // error while login
      message.setErrorMessage(extractErroMessage(err.code));
    }).finally(()=>{
      setLoading(false)
    })


  }
  function handleSignInWithGoogle(event) {
    // function to handle sign in with google
    // alert("handle signin with google")
    const provider=new GoogleAuthProvider();
    provider.setCustomParameters({
      'login_hint': 'user@example.com'
    });
    setLoading(true)
    signInWithPopup(getAuth(firebase_app),provider).then((result)=>{
      // 
      navigate("/");
    }).catch(err=>{
      // handle error
      message.setErrorMessage(extractErroMessage(err.code))
    }).finally(()=>{
      setLoading(false)
    })
  }
  return (
    <>
      {/* email input field */}
      <CustomInput
        disabled={loading}
        inputRef={emailRef}
        onKeyDown={(event) => {
          keyDownHandle(event, emailRef, passwordRef);
        }}
        label="Email"
        required
        autoFocus
      />
      {/* password input field */}
      <CustomInput
        disabled={loading}
        inputRef={passwordRef}
        onKeyDown={(event) => {
          keyDownHandle(event, passwordRef, buttonRef);
        }}
        type="password"
        label="Password"
        required
      />
      {/* forgot password */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <ForgotPassword />
        <CreateNewAccout />
      </div>
      {/* sign in button */}
      <CustomButton
        disabled={loading}
        ref={buttonRef}
        onClick={submitHandle}
        variant="outlined"
      >
        Sign In
      </CustomButton>
      {/* alternative authentication options */}
      <AlternativeAuth
        loading={loading}
        onClick={handleSignInWithGoogle}
        title="Sign in with Google"
      />
    </>
  );
}