// forgotPassword.js
// this component renders a forgotPassword element
import { useNavigate } from "react-router-dom";
import Style from "./forgotPassword.module.css";
export default function ForgotPassword(props) {
  const navigate = useNavigate();

  return (
    <button onClick={()=>{navigate("/auth/reset")}} className={Style.button}>
      <span
        style={{
          display: "block",
        }}
      >
        Forgot Password ?
      </span>
    </button>
  );
}
